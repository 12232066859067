<!--  -->
<template>
  <div class="content">
   <top-title>
      <span slot="left">调用详情</span>
      <span slot="right">调用详情</span>
    </top-title>
    <div class="data">
      <div class="inner">
        <el-button type="primary" size='mini' @click="$router.push({name:'api'})" icon="el-icon-arrow-left" style="margin-bottom:10px">返回</el-button>
        <el-table
          :data="tableData"
          style="width: 100%;margin-bottom:20px"
          max-height="750"
          v-loading="loading"
        >
          <el-table-column label="接口名称" >
            {{ apiInfo.name }}
          </el-table-column>
          <el-table-column label="接口路径">
            {{ apiInfo.apiAddress }}
          </el-table-column>
          <el-table-column prop="createTime" label="调用时间" >
          </el-table-column>
          <el-table-column prop="elapsedTime" label="耗时(ms)">
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            prop="invokeParams"
            label="调用参数"
          >
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            prop="resultData"
            label="响应结果"
          >
          </el-table-column>
        </el-table>
        <pagination
          :total="page.total"
          :current-page="page.current"
          :page-size="page.size"
          @refreshData="queryList"
        ></pagination>
      </div>
    </div>
  </div>
</template>

<script>
import TopTitle from "@/components/common/top-title";
import TopTitleLink from "@/components/common/top-title-link";
import FortressPagination from "@/components/common/pagination/pagination.vue";
import { apiLogPage, apiInfo } from "@/api/api.js";
export default {
  data() {
    return {
      memberId: "",
      page: {
        current: 1,
        size: 6,
        total: 0,
      },
      tableData: [],
      queryForm: {},
      loading: false,
      id: "",
      apiInfo: {},
    };
  },
  created() {
    this.id = this.$route.query.id;
    if (!this.id) {
      this.$router.push({ name: "api" });
    }
    this.getApiInfo(this.id);
  },

  components: {
    TopTitle,
    TopTitleLink,
    pagination: FortressPagination,
  },

  computed: {},

  methods: {
    queryList(page) {
      this.loading = true;
      this.tableData = [];
      if (page) {
        this.page.current = page.current ? page.current : this.page.current;
        this.page.size = page.size ? page.size : this.page.size;
      }
      this.queryForm.current = this.page.current;
      this.queryForm.size = this.page.size;
      this.queryForm.memberId = JSON.parse(localStorage.getItem("userInfo")).id;
      apiLogPage(this.queryForm).then((resp) => {
        this.loading = false;
        if (resp.code == 0) {
          this.tableData = resp.data.records;
          this.page.total = resp.data.total;
          //查询页大于总页数，重新查询
          let cu =
            this.page.total % this.page.size == 0
              ? parseInt(this.page.total / this.page.size)
              : parseInt(this.page.total / this.page.size + 1);
          if (cu > 0 && this.page.current > cu) {
            this.queryList({ current: cu });
          }
        } else {
          this.$message({
            message: resp.msg,
            type: "error",
          });
        }
      });
    },
    //获取接口详情
    getApiInfo(id) {
      apiInfo(id).then((resp) => {
        if (resp.code == 0) {
          this.apiInfo = resp.data;
          if (this.apiInfo) {
            this.queryForm.invokeUrl = this.apiInfo.apiAddress;
            this.queryList();
          }
        } else {
          this.$message({
            message: resp.msg,
            type: "error",
          });
        }
      });
    },
    //状态格式化
    statusFmt(row) {
      let name = "";
      if (row.status == "1") {
        name = "未支付";
      } else {
        name = "已支付";
      }
      return name;
    },
  },
};
</script>
<style lang='scss' scoped>
.content {
  width: 100%;
  .data {
    padding: 20px;
    margin: 0 0 20px 0;
    background: #fff;
    .inner {
      .col-name {
        margin-right: 20px;
        color: #999;
        font-size: 14px;
        width: 7%;
      }
      .col-content {
        span {
          font-size: 14px;
          color: #666;
          font-weight: bold;
        }
      }
    }
  }
}

em {
  position: relative;
  &.success {
    color: $success;
    &::before {
      content: "已认证";
    }
  }
  &.error {
    color: $danger;
    &::before {
      content: "未认证";
    }
  }
}

@media screen and (min-width: 992px) {
  .content {
    .part1 {
      .col-md9 {
        margin-bottom: 0;
      }
      .price {
        margin-right: 20px;
      }
    }
  }
}
</style>