<template>
  <router-link :to="to" class="top-title-link">
    <slot></slot>
  </router-link>
</template>

<script >
export default {
  name: "TopTitleLink",
  props: {
    to: String,
  },
};
</script>

<style lang="scss" scoped>
.top-title-link {
  display: inline-block;
  color: $color;
  &::after {
    content: ">";
    margin-left: 0.5em;
  }
}
</style>
